import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./components/Login";
import Register from "./components/Register";
import Notes from "./components/Notes";
import Add from "./components/Add";
import "./my.css";
import Edit from "./components/Edit";
import Guest from "./components/Guest";

function App() {
  return (
    <BrowserRouter>
      <div>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/notes" element={<Notes />} />
          <Route path="/note/add" element={<Add />} />
          <Route path="/note/edit/:id" element={<Edit />} />
          <Route path="/guest" element={<Guest />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
