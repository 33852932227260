import React, { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useGoogleLogin } from "@react-oauth/google";

const Register = () => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [spinnerGoogle, setSpinnerGoogle] = useState(false);
  const navigate = useNavigate();

  const register = async (e) => {
    e.preventDefault();
    setSpinner(true);

    try {
      logout();
      const response = await axios.post(`${apiUrl}/users`, {
        name,
        email,
        password,
        confPassword,
      });
      navigate("/notes", {
        state: {
          age: new Date().setSeconds(new Date().getSeconds() + 1),
          msg: response.data.msg,
        },
      });
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.msg, {
          position: "bottom-right",
        });
      }
      setSpinner(false);
      console.clear();
    }
  };

  const registerWithGoogle = useGoogleLogin({
    onSuccess: async (responseGoogle) => {
      try {
        logout();
        setSpinnerGoogle(true);
        const res = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${responseGoogle.access_token}`,
            },
            withCredentials: false,
          }
        );
        const name = res.data.name;
        const email = res.data.email;
        const response = await axios.post(`${apiUrl}/users/google`, {
          name,
          email,
        });
        navigate("/notes", {
          state: {
            age: new Date().setSeconds(new Date().getSeconds() + 1),
            msg: response.data.msg,
          },
        });
      } catch (error) {
        if (error.response.status === 400) {
          try {
            const res = await axios.get(
              "https://www.googleapis.com/oauth2/v3/userinfo",
              {
                headers: {
                  Authorization: `Bearer ${responseGoogle.access_token}`,
                },
                withCredentials: false,
              }
            );
            const email = res.data.email;
            const response = await axios.post(`${apiUrl}/login/google`, {
              email,
            });
            navigate("/notes", {
              state: {
                age: new Date().setSeconds(new Date().getSeconds() + 1),
                msg: `Welcome back ${response.data.name}!`,
              },
            });
          } catch (error) {
            setSpinnerGoogle(false);
            console.clear();
          }
        } else if (error.response) {
          toast.error(error.response.data.msg, {
            position: "bottom-right",
          });
        }
        setSpinnerGoogle(false);
        console.clear();
      }
    },
  });

  const logout = async () => {
    try {
      await axios.delete(`${apiUrl}/logout`);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <section className="vh-100">
        <div className="container py-5 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-12 col-md-8 col-lg-6 col-xl-5">
              <div className="card shadow mb-5">
                <div className="card-header text-center d-flex">
                  <h4 className="m-auto my-3">Register</h4>
                </div>
                <div className="card-body p-5">
                  <form onSubmit={register}>
                    <div className="form-outline mb-4">
                      <label className="form-label" htmlFor="name">
                        Name
                      </label>
                      <input
                        type="text"
                        id="name"
                        className="form-control"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Someone"
                        required
                        autoFocus
                      />
                    </div>
                    <div className="form-outline mb-4">
                      <label className="form-label" htmlFor="email">
                        Email
                      </label>
                      <input
                        type="email"
                        id="email"
                        className="form-control"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="example@gmail.com"
                        required
                      />
                    </div>
                    <div className="form-outline mb-4">
                      <label className="form-label" htmlFor="password">
                        Password
                      </label>
                      <input
                        type="password"
                        id="password"
                        className="form-control"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="******"
                        minLength="6"
                        required
                      />
                    </div>
                    <div className="form-outline mb-4">
                      <label className="form-label" htmlFor="confPassword">
                        Confirm Password
                      </label>
                      <input
                        type="password"
                        id="confPassword"
                        className="form-control"
                        value={confPassword}
                        onChange={(e) => setConfPassword(e.target.value)}
                        placeholder="******"
                        minLength="6"
                        required
                      />
                    </div>
                    <button
                      type="submit"
                      data-mdb-button-init
                      data-mdb-ripple-init
                      className="btn btn-primary mb-2 w-100"
                      disabled={spinner}
                    >
                      {spinner ? (
                        <div
                          className="spinner-border spinner-border-sm"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        "Register"
                      )}
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger mb-4 w-100"
                      onClick={() => registerWithGoogle()}
                    >
                      {spinnerGoogle ? (
                        <div
                          className="spinner-border spinner-border-sm"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        "Register with Google"
                      )}
                    </button>
                    <div className="text-center">
                      <Link to="/">Login</Link>
                    </div>
                  </form>
                </div>
                <div className="card-footer d-flex p-3">
                  <p className="m-auto">
                    © 2024 <Link to="/">NotesApp</Link> by{" "}
                    <Link to="https://fergus.my.id" target="_blank">
                      Ferdi Gusnanto
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </div>
  );
};

export default Register;
