import React, { useState, useEffect } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "./atoms/Navbar";
import Footer from "./atoms/Footer";

const Notes = () => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [notes, setNotes] = useState([]);
  const [spinner, setSpinner] = useState(true);
  const [deleteSpinner, setDeleteSpinner] = useState([false, ""]);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    refreshToken();
    getNotes();
    if (location.state) {
      if (new Date().getTime() < location.state.age)
        toast.success(location.state.msg, {
          position: "bottom-right",
        });
    }
    console.clear();
    // eslint-disable-next-line
  }, []);

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${apiUrl}/token`);
      const decoded = jwtDecode(response.data.accessToken);
      setId(decoded.userId);
      setName(decoded.name);
    } catch (error) {
      if (error.response) {
        navigate("/");
      }
      console.clear();
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      try {
        const response = await axios.get(`${apiUrl}/token`);
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        const decoded = jwtDecode(response.data.accessToken);
        setName(decoded.name);
      } catch (error) {
        if (error.response) {
          navigate("/");
        }
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getNotes = async (spinner) => {
    try {
      const response = await axiosJWT.get(`${apiUrl}/notes`);
      setSpinner(false);
      setNotes(response.data);
    } catch (error) {
      console.clear();
    }
  };

  const deleteNote = async (id) => {
    setDeleteSpinner([true, id]);
    try {
      const response = await axiosJWT.delete(`${apiUrl}/note/${id}`);
      toast.success(response.data.msg, {
        position: "bottom-right",
      });
      getNotes();
    } catch (error) {
      console.clear();
    }
  };

  return (
    <div className="d-flex flex-column min-vh-100">
      <Navbar name={name} />
      <div className="container mt-5 py-5">
        <div className="d-flex">
          <Link to="/note/add" className="btn btn-primary">
            <i className="fas fa-plus me-2"></i>Add Note
          </Link>
          {id === "66286fbf6593fe840d25d8f6" ? (
            <Link to="/register" className="my-auto ms-auto">
              Create your own Notes
            </Link>
          ) : (
            ""
          )}
        </div>
        <div className="table-responsive mt-3 h-100">
          <table className="table table-bordered table-striped align-middle">
            <thead>
              <tr>
                <th scope="col" width="30">
                  No
                </th>
                <th scope="col">Date</th>
                <th scope="col">Name</th>
                <th scope="col" width="125">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {spinner ? (
                <tr>
                  <td colSpan={4} className="text-center">
                    <div
                      className="spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </td>
                </tr>
              ) : notes.length !== 0 ? (
                notes.map((note, index) => (
                  <tr key={note._id}>
                    <th scope="row">{index + 1}</th>
                    <td>{`${("0" + new Date(note.date).getDate()).slice(-2)}/${(
                      "0" +
                      (new Date(note.date).getMonth() + 1)
                    ).slice(-2)}/${new Date(note.date).getFullYear()}`}</td>
                    <td>{note.name}</td>
                    <td>
                      <button
                        className="btn btn-info btn-sm my-1 me-1"
                        data-bs-toggle="modal"
                        data-bs-target={`#${note._id}`}
                      >
                        <i className="fa-solid fa-eye"></i>
                      </button>
                      <Link
                        to={`/note/edit/${note._id}`}
                        className="btn btn-success btn-sm my-1 me-1"
                        title="Edit"
                      >
                        <i className="fas fa-pencil"></i>
                      </Link>
                      <button
                        onClick={() =>
                          window.confirm(`Delete ${note.name}?`)
                            ? deleteNote(note._id)
                            : ""
                        }
                        className="btn btn-danger btn-sm my-1"
                        title="Delete"
                        disabled={
                          note._id === "6628710f81a5fcdfea3c3b95"
                            ? true
                            : deleteSpinner[0] && deleteSpinner[1] === note._id
                            ? true
                            : false
                        }
                      >
                        {deleteSpinner[1] === note._id ? (
                          <div
                            className="spinner-border spinner-border-sm"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        ) : (
                          <i className="fa-solid fa-trash"></i>
                        )}
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={4} className="text-center">
                    No Notes
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {notes.map((note) => (
        <div
          className="modal fade"
          id={note._id}
          tabIndex="-1"
          aria-labelledby="noteModal"
          aria-hidden="true"
          key={note._id}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="noteModal">
                  {note.name}
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div
                className="modal-body notes"
                dangerouslySetInnerHTML={{ __html: note.notes }}
              ></div>
            </div>
          </div>
        </div>
      ))}
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default Notes;
