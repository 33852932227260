import React, { useState, useEffect } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./atoms/Footer";
import Navbar from "./atoms/Navbar";

const Add = () => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const [name, setName] = useState("");
  const [date, setDate] = useState(
    `${new Date().getFullYear()}-${("0" + (new Date().getMonth() + 1)).slice(
      -2
    )}-${new Date().getDate()}`
  );
  const [noteName, setNoteName] = useState("");
  const [notes, setNotes] = useState("");
  const [spinner, setSpinner] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    refreshToken();
    // eslint-disable-next-line
  }, []);

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${apiUrl}/token`);
      const decoded = jwtDecode(response.data.accessToken);
      setName(decoded.name);
    } catch (error) {
      if (error.response) {
        navigate("/");
      }
      console.clear();
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      try {
        const response = await axios.get(`${apiUrl}/token`);
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        const decoded = jwtDecode(response.data.accessToken);
        setName(decoded.name);
      } catch (error) {
        if (error.response) {
          navigate("/");
        }
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const saveNote = async (e) => {
    e.preventDefault();
    setSpinner(true);

    try {
      const response = await axiosJWT.post(`${apiUrl}/note`, {
        date,
        name: noteName,
        notes,
      });
      navigate("/notes", {
        state: {
          age: new Date().setSeconds(new Date().getSeconds() + 1),
          msg: response.data.msg,
        },
      });
    } catch (error) {
      if (error.response.status === 403) {
        toast.error(error.response.data.msg, {
          position: "bottom-right",
        });
      }
      setSpinner(false);
      console.clear();
    }
  };

  const back = () => {
    navigate("/notes");
  };

  return (
    <div className="d-flex flex-column min-vh-100">
      <Navbar name={name} />
      <div className="container mt-5 py-5">
        <form onSubmit={saveNote}>
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="date" className="form-label">
                  Date
                </label>
                <input
                  type="date"
                  className="form-control"
                  id="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="noteName" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="noteName"
                  value={noteName}
                  onChange={(e) => setNoteName(e.target.value)}
                  placeholder="Name of Note"
                  autoFocus
                  required
                />
              </div>
            </div>
          </div>
          <div className="mb-3">
            <label className="form-label">Notes</label>
            <ReactQuill theme="snow" value={notes} onChange={setNotes} />
          </div>
          <button
            type="submit"
            className="btn btn-primary me-1"
            disabled={spinner}
          >
            {spinner ? (
              <div className="spinner-border spinner-border-sm" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              <span>
                <i className="fa-solid fa-floppy-disk me-2"></i>Save
              </span>
            )}
          </button>
          <button type="button" className="btn btn-secondary" onClick={back}>
            <i className="fa-solid fa-reply me-2"></i>Back
          </button>
        </form>
      </div>
      <ToastContainer />
      <Footer />
    </div>
  );
};

export default Add;
