import React, { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

const Navbar = ({ name }) => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const [spinner, setSpinner] = useState(false);
  const navigate = useNavigate();

  const logout = async () => {
    setSpinner(true);
    try {
      const response = await axios.delete(`${apiUrl}/logout`);
      navigate("/", {
        state: {
          age: new Date().setSeconds(new Date().getSeconds() + 1),
          msg: response.data.msg,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <nav className="navbar navbar-expand-lg bg-body-tertiary fixed-top">
      <div className="container">
        <Link to="/notes" className="navbar-brand ms-2 ms-md-0" href="#">
          <i className="fa-solid fa-pen-to-square me-2"></i>NotesApp
        </Link>
        {spinner ? (
          <div
            className="spinner-border spinner-border-sm me-2 me-md-0"
            role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        ) : (
          <div className="dropdown me-2 me-md-0">
            <div type="button" data-bs-toggle="dropdown" aria-expanded="false">
              <i className="fas fa-user"></i>
              {name ? (
                <div className="d-none d-md-inline ms-2">{name}</div>
              ) : (
                <div className="d-none d-md-inline">
                  <div
                    className="spinner-border spinner-border-sm ms-2"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              )}
            </div>
            <ul className="dropdown-menu dropdown-menu-end">
              <li className="dropdown-item d-block d-md-none">
                <i className="fas fa-user me-2"></i>
                {name}
              </li>
              <li className="d-block d-md-none">
                <hr className="dropdown-divider" />
              </li>
              <li>
                <Link
                  className="dropdown-item"
                  onClick={() => (window.confirm("Logout?") ? logout() : "")}
                >
                  <i className="fa-solid fa-right-from-bracket me-2"></i>Logout
                </Link>
              </li>
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
