import React, { useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Guest = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  useEffect(() => {
    login();
    // eslint-disable-next-line
  }, []);

  const login = async () => {
    try {
      const response = await axios.post(`${apiUrl}/login`, {
        email: "guest@gmail.com",
        password: "123456",
      });
      navigate("/notes", {
        state: {
          age: new Date().setSeconds(new Date().getSeconds() + 1),
          msg: `Welcome back ${response.data.name}!`,
        },
      });
    } catch (error) {
      if (error.response) {
        navigate("/");
      }
      // console.clear();
    }
  };

  return (
    <div className="text-center py-5">
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};

export default Guest;
