import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useGoogleLogin } from "@react-oauth/google";
import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [spinnerGoogle, setSpinnerGoogle] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    refreshToken();
    if (location.state) {
      if (new Date().getTime() < location.state.age)
        toast.success(location.state.msg, {
          position: "bottom-right",
        });
    }
    // eslint-disable-next-line
  }, []);

  const refreshToken = async () => {
    try {
      await axios.get(`${apiUrl}/token`);
      navigate("/notes");
    } catch (error) {
      console.clear();
    }
  };

  const Login = async (e) => {
    e.preventDefault();
    setSpinner(true);

    try {
      const response = await axios.post(`${apiUrl}/login`, {
        email,
        password,
      });
      navigate("/notes", {
        state: {
          age: new Date().setSeconds(new Date().getSeconds() + 1),
          msg: `Welcome back ${response.data.name}!`,
        },
      });
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.msg, {
          position: "bottom-right",
        });
      }
      setSpinner(false);
      console.clear();
    }
  };

  const loginWithGoogle = useGoogleLogin({
    onSuccess: async (responseGoogle) => {
      try {
        setSpinnerGoogle(true);
        const res = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${responseGoogle.access_token}`,
            },
            withCredentials: false,
          }
        );
        const email = res.data.email;
        const response = await axios.post(`${apiUrl}/login/google`, {
          email,
        });
        navigate("/notes", {
          state: {
            age: new Date().setSeconds(new Date().getSeconds() + 1),
            msg: `Welcome back ${response.data.name}!`,
          },
        });
      } catch (error) {
        if (error.response.status === 404) {
          try {
            const res = await axios.get(
              "https://www.googleapis.com/oauth2/v3/userinfo",
              {
                headers: {
                  Authorization: `Bearer ${responseGoogle.access_token}`,
                },
                withCredentials: false,
              }
            );
            const name = res.data.name;
            const email = res.data.email;
            const response = await axios.post(`${apiUrl}/users/google`, {
              name,
              email,
            });
            navigate("/notes", {
              state: {
                age: new Date().setSeconds(new Date().getSeconds() + 1),
                msg: response.data.msg,
              },
            });
          } catch (error) {
            if (error.response) {
              toast.error(error.response.data.msg, {
                position: "bottom-right",
              });
            }
            setSpinnerGoogle(false);
            console.clear();
          }
        } else if (error.response) {
          toast.error(error.response.data.msg, {
            position: "bottom-right",
          });
        }
        setSpinnerGoogle(false);
        console.clear();
      }
    },
    text: "signup_with",
  });

  return (
    <section className="vh-100">
      <div className="container py-5 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-12 col-md-8 col-lg-6 col-xl-5">
            <div className="card shadow mb-5">
              <div className="card-header d-flex">
                <h4 className="m-auto my-3">Login</h4>
              </div>
              <div className="card-body p-5">
                <form onSubmit={Login}>
                  <div className="form-outline mb-4">
                    <label className="form-label" htmlFor="email">
                      Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      className="form-control"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="example@gmail.com"
                      required
                      autoFocus
                    />
                  </div>
                  <div className="form-outline mb-4">
                    <label className="form-label" htmlFor="password">
                      Password
                    </label>
                    <input
                      type="password"
                      id="password"
                      className="form-control"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="******"
                      minLength="6"
                      required
                    />
                  </div>
                  <button
                    type="submit"
                    data-mdb-button-init
                    data-mdb-ripple-init
                    className="btn btn-primary mb-2 w-100"
                    disabled={spinner}
                  >
                    {spinner ? (
                      <div
                        className="spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    ) : (
                      "Login"
                    )}
                  </button>
                </form>
                <button
                  className="btn btn-danger mb-4 w-100"
                  onClick={() => {
                    loginWithGoogle();
                  }}
                  disabled={spinnerGoogle}
                >
                  {spinnerGoogle ? (
                    <div
                      className="spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    "Login with Google"
                  )}
                </button>
                <div className="d-flex">
                  <Link to="register">Register</Link>
                  <Link to="guest" className="ms-auto">
                    Login as Guest
                  </Link>
                </div>
              </div>
              <div className="card-footer d-flex p-3">
                <p className="m-auto">
                  © 2024 <Link to="/">NotesApp</Link> by{" "}
                  <Link to="https://fergus.my.id" target="_blank">
                    Ferdi Gusnanto
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </section>
  );
};

export default Login;
